<template>
  <div>
    <form @submit.prevent="dispatchSubmit">
      <div class="row">
        <div class="col-lg-12">
          <div class="alt-label label required">
            Levarei um acompanhante para o cruzeiro? Selecione uma das opções para continuar:
          </div>
        </div>
        <div class="col-lg-5 a-f">
          <IConfirm
              :is-disabled="formIsDisabled"
              v-model="enableCompanion"
              wo-label
          />
        </div>
      </div>
      <div v-show="enableCompanion">
        <div class="row">
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.name" ref="name" class="col-lg" label="Nome"/>
          <IText
            :is-disabled="formIsDisabled"
            v-model="formData.last_name" ref="last_name" class="col-lg" label="Último sobrenome"/>
          <IText
            :is-disabled="formIsDisabled"
            v-model="formData.badge_name" ref="badge_name" class="col-lg" label="Nome e Sobrenome para crachá"/>
        </div>
        <div class="row">
          <IGender
            :is-disabled="formIsDisabled"
            v-model="formData.gender" ref="gender" class="col-lg-4" label="Sexo"/>
          <ICountry
            :is-disabled="formIsDisabled"
            v-model="formData.nationality" ref="nationality" class="col-lg-4" label="Nacionalidade"/>
          <ICpf
            :is-disabled="formIsDisabled"
            v-model="formData.cpf" ref="cpf" class="col-lg-4" label="CPF"/>
        </div>
        <div class="row">
          <ITelCc
            :is-disabled="formIsDisabled"
            @country-code="updateCoutryCode"
            placeholder="Numero de celular" 
            v-model="formData.phone" ref="phone" class="col-lg-4" label="Número de celular" />
          <IEmail
            :is-disabled="formIsDisabled"
            v-model="formData.email" ref="email" class="col-lg" label="E-mail"/>
        </div>
        <div class="row">
          <IDate
              :is-disabled="formIsDisabled"
              v-model="formData.birth_date" ref="birth_date" class="col-lg-3" label="Data de Nascimento" placeholder="dd/mm/aaaa" :min-age="18"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.identification_doc" ref="identification_doc" class="col-lg-3" label="Número de Identidade"/>
          <IDate
              :is-disabled="formIsDisabled"
              v-model="formData.doc_emission" ref="doc_emission" class="col-lg-3" label="Data de emissão" placeholder="dd/mm/aaaa"/>
          <IDate
              :is-disabled="formIsDisabled"
              v-model="formData.doc_validity" ref="doc_validity" class="col-lg-3" label="Data validade" placeholder="dd/mm/aaaa" :validate-current-date="true"/>
        </div>
        <div class="row">
          <ICountry
            :is-disabled="formIsDisabled"
            v-model="formData.doc_country" ref="doc_country" class="col-lg" label="País de emissão do documento"/>
          <IText
            :is-disabled="formIsDisabled"
            v-model="formData.birth_city" ref="birth_city" class="col-lg" label="Cidade de Nascimento"/>
        </div>
        <div class="row">
          <ICep
              :is-disabled="formIsDisabled"
              v-model="formData.zip_code" ref="zip_code" class="col-lg-2" label="CEP"
              @update-address="(data) => updateAddress(data)"
          />
          <IText
            :is-disabled="formIsDisabled"
            v-model="formData.address" ref="address" class="col-lg" label="Endereço completo"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.city" ref="city" class="col-lg-4" label="Cidade de residência"/>
          <IUf
            :is-disabled="formIsDisabled"
            v-model="formData.state" ref="state" class="custom-col-uf" label="UF"/>
        </div>
        <div class="row">
          <ICountry
            :is-disabled="formIsDisabled"
            v-model="formData.country" ref="country" class="col-lg" label="País"/>
          <IText
            :is-disabled="formIsDisabled"
            v-model="formData.address_complement" ref="address_complement" class="col-lg" label="Complemento"/>
        </div>
        <div class="row">
          <IConfirm
              :is-disabled="formIsDisabled"
              v-model="formData.has_food_restriction" ref="has_food_restriction" class="col-lg-12"
              label="Restrição medicamentosa, alimentar, substâncias ou algo que precisamos ter ciência:"/>
          <IText v-show="formData.has_food_restriction"
                 :is-disabled="formIsDisabled"
                 v-model="formData.food_restriction" ref="food_restriction"
                 class="col-lg" label="Especifique a restrição"/>
        </div>
        <div class="c-s text-label">VESTUÁRIO</div>
        <div class="row">
          <ITshirt
            :is-disabled="formIsDisabled"
            v-model="formData.polo_tshirt" ref="polo_tshirt" class="col-lg" label="Tamanho de camisa polo"/>
          <IShoe
            :is-disabled="formIsDisabled"
            v-model="formData.shoe_size" ref="shoe_size" class="col-lg" label="Tamanho de calçado"/>
        </div>
        <div class="row">
          <div class="col-lg">
            <div class="text-label">DOCUMENTOS</div>
            <div class="text-label text-label-small">Não são aceitas carteiras funcionais</div>
            <IFile
              :is-disabled="formIsDisabled"
              v-model="formData.rg_file" ref="rg_file" class="i-file col-lg">Anexe aqui uma cópia do <span
              class="font-bold">documento de identificação com foto de seu acompanhante <small style="font-weight: bold">(RG, CNH, OAB, RNE ou Passaporte)</small></span>
            </IFile>
          </div>
        </div>
        <div class="row c-s">
          <div class="col-lg-8">
            <IConfirm label="O seu acompanhante precisará de passagem aérea para chegar até o evento no Rio de Janeiro?" v-model="enableFly" ref="enableFly" :is-disabled="formIsDisabled"/>
          </div>
        </div>
        <div class="row" v-show="enableFly">
          <div class="text-label">TRANSPORTE AÉREO</div>
          <div class="text-label text-label-small">
            Fique atento às informações fornecidas para que as passagens possam ser emitidas corretamente. Não serão realizadas reemissões de passagens<br />
          </div>
          <IDateTransp
              :is-disabled="formIsDisabled"
              v-model="formData.boarding_date" ref="boarding_date" class="col-lg-4" label="Data de chegada ao Rio de Janeiro" placeholder="dd/mm/aaaa"/>
          <IText
              class="col-lg-8"
              label="Aeroporto de embarque"
              :is-disabled="formIsDisabled"
              ref="departure_airport"
              v-model="formData.departure_airport"
          />
          <IDateTransp
              :is-disabled="formIsDisabled"
              :returnDate="formData.boarding_date"
              v-model="formData.return_date" ref="return_date" class="col-lg-4" label="Data de retorno para sua cidade de origem" placeholder="dd/mm/aaaa"/>
          <IText
              class="col-lg-8"
              label="Aeroporto de retorno para sua cidade"
              :is-disabled="formIsDisabled"
              ref="return_airport"
              v-model="formData.return_airport"
          />
        </div>
        <div class="c-s text-label">CONTATO DE EMERGÊNCIA</div>
        <div class="text-label text-label-small">
          Forneça a seguir informações do seu contato de emergência. <span class="font-bold">Importante: esta pessoa não deverá estar viajando com você.</span>
        </div>
        <div class="row">
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.contact.name" ref="contact_name" class="col-lg-5" label="Nome completo"/>
          <ITelCc
              :is-disabled="formIsDisabled"
              @country-code="updateECoutryCode"
              placeholder="Numero de celular" 
              v-model="formData.contact.phone" ref="contact_phone" class="col-lg-4" label="Número de celular"/>
          <IText
              :is-disabled="formIsDisabled"
              v-model="formData.contact.type" ref="contact_type" class="col-lg-3" label="Grau de parentesco"/>
        </div>
        <div class="row">
          <ITextarea
              :is-disabled="formIsDisabled"
              v-model="formData.obs"
              ref="obs"
              class="col-lg"
              label="Comentários adicionais"
              placeholder="Utilize este espaço para pedidos, dúvidas, entre outros." :is-required="false"
          />
        </div>
        <div class="c-s row" v-if="showPregnancyWarning">
          <div class="col-lg">
            <div class="text-label text-label-small disclaimer-gestante">
              <div class="text-label">IMPORTANTE</div>
              Gestantes que completarem 23 semanas de gravidez até o término do cruzeiro (11 janeiro de 2025), deverão apresentar atestado médico de aptidão para
    viajar. Por razões de saúde e segurança, o Transportador não poderá transportar Passageiras completando 24 semanas de gestação ou mais na data do embarque.
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import IText from "../inputs/i-text";
import ITelCc from "../inputs/i-tel-cc";
import IEmail from "../inputs/i-email";
import ICpf from "../inputs/i-cpf";
import IDate from "../inputs/i-date";
import IDateTransp from "../inputs/i-datetransp";
import ICep from "../inputs/i-cep";
import IUf from "../inputs/i-uf";
import ITshirt from "../inputs/i-shirt";
import IShoe from "../inputs/i-shoe";
import ICountry from "../inputs/i-country";
import IConfirm from "../inputs/i-confirm";
import IGender from "../inputs/i-gender";
import IFile from "../inputs/i-file";
import ITextarea from "../inputs/i-textarea";
// import UiDisclaim from "../ui-disclaim";

export default {
  name: "form-f2-e2",
  components: {ITextarea, IConfirm, IUf, IGender, ICountry, ICep, IDate, IDateTransp, ICpf, IEmail, ITelCc, IText, ITshirt, IShoe, IFile},
  data: () => ({
    formData: {
      name: null,
      last_name: null,
      badge_name: null,
      nationality: null,
      phone: null,
      gender: null,
      email: null,
      cpf: null,
      birth_date: null,
      identification_doc: null,
      doc_emission: null,
      doc_validity: null,
      doc_country: null,
      birth_city: null,
      zip_code: null,
      state: null,
      city: null,
      address: null,
      country: null,
      address_complement: null,
      food_restriction: null,
      hotel_reservation: null,
      has_food_restriction: null,
      rg_file: null,
      polo_tshirt: null,
      shoe_size: null,
      // covid_file: null,
      departure_airport: null,
      return_airport: null,
      boarding_date: null,
      return_date: null,
      contact: {
        type: null,
        name: null,
        phone: null,
      },
      obs: null,
    },
    formIsDisabled: false,
    coutryCode: null,
    ecoutryCode: null,
    enableCompanion: null,
    enableFly: null,
  }),
  methods: {
    getValue() {
      if (this.enableCompanion === false) {
        return null;
      }
      const shallowCopy = {...this.formData, contact: {...this.formData.contact}};
      delete shallowCopy.has_food_restriction;
      if (!this.formData.has_food_restriction) {
        delete shallowCopy.food_restriction;
      }
      shallowCopy.phone = `+${this.coutryCode}${this.formData.phone}`;
      shallowCopy.contact.phone = `+${this.ecoutryCode}${this.formData.contact.phone}`;
      shallowCopy.enableFly = this.enableFly;
      return shallowCopy;
    },
    isValid() {
      if (this.enableCompanion === false) {
        return true;
      }
      const normalRef = this.getNormalRef();
      if (this.formData.has_food_restriction) {
        normalRef.push(this.$refs.food_restriction);
      }
      if (this.enableFly) {
        normalRef.push(...[
          this.$refs.return_airport,
          this.$refs.return_date,
          this.$refs.departure_airport,
          this.$refs.boarding_date
        ]);
      }
      return normalRef.reduce((last, ref) => ref?.isValid() && last, true);
    },
    setValue() {
    },
    setDisabled(isDisabled) {
      this.formIsDisabled = isDisabled;
    },
    getNormalRef() {
      return [
        this.$refs.name,
        this.$refs.last_name,
        this.$refs.badge_name,
        this.$refs.phone,
        this.$refs.gender,
        this.$refs.email,
        this.$refs.nationality,
        this.$refs.cpf,
        this.$refs.birth_date,
        this.$refs.identification_doc,
        this.$refs.doc_emission,
        this.$refs.doc_validity,
        this.$refs.doc_country,
        this.$refs.birth_city,
        this.$refs.zip_code,
        this.$refs.state,
        this.$refs.city,
        this.$refs.country,
        this.$refs.address,
        this.$refs.address_complement,
        this.$refs.has_food_restriction,
        this.$refs.rg_file,
        this.$refs.polo_tshirt,
        this.$refs.shoe_size,
        // this.$refs.covid_file,
        this.$refs.contact_name,
        this.$refs.contact_phone,
        this.$refs.contact_type,
        this.$refs.obs,
        this.$refs.enableFly,
      ];
    },
    dispatchSubmit() {
      if (this.isValid()) {
        this.$emit('submit');
      }
    },
    updateAddress(addressData) {
      this.formData.state = addressData.state;
      this.formData.city = addressData.city;
      this.formData.address = addressData.address;
      if (addressData) {
        this.formData.country = 'Brasil';
      }
    },
    updateCoutryCode(code) {
      this.coutryCode = code
    },
    updateECoutryCode(code) {
      this.ecoutryCode = code
    }
  },
  computed: {
    age() {
      if (!this.formData.birth_date) return null;
      const birthDate = new Date(this.formData.birth_date);
      const ageDiff = Date.now() - birthDate.getTime();
      const ageDate = new Date(ageDiff);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    showPregnancyWarning() {
      return this.formData.gender === 'Feminino' && this.age !== null && this.age < 50;
    }
  },
}
</script>

<style scoped>
.i-file {
  margin-bottom: 1rem;
  max-width: 540px;
}
.c-s {
  margin-top: 2.5rem;
}
.a-f {
  margin-bottom: 20px;
}
.disclaimer-gestante {
  background: #ff00001f;
  padding: 0.75rem;
  border-radius: 5px;
  color: #000;
}
</style>
