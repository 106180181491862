var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "body"
  }, [_vm._m(0), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "headline"
  }, [_vm._v(" " + _vm._s(_vm.$t('')) + " ")]), _c('div', {
    staticClass: "form-container"
  }, [_c('RouterView')], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center"
    }
  }, [_c('img', {
    staticClass: "logo",
    attrs: {
      "src": "https://xcode-eventfy.sfo3.digitaloceanspaces.com/magcruise/mag-cruise-logo.png",
      "alt": "Logo"
    }
  })]), _c('div', {
    staticClass: "subtitle-he"
  }, [_c('a', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v("8 a 11 de janeiro de 2025 - MSC ORCHESTRA ")])])]);
}]

export { render, staticRenderFns }