<template>
  <div>
    <div v-show="!isStepCONCLUSAO && !isStepDECLINADO" class="container">
      <div class="nav">
        <div
            class="nav-item"
            :class="{'active': isStepINITIAL, 'hidden': !hasIdentificacao}"
        >
          {{ $t('Identificação') }} <span class="legenda-l1" v-show="isStepINITIAL">/ Identification</span>
        </div>
        <div
            class="nav-item"
            :class="{'hidden': isStepINITIAL || isStepPAGAMENTO, 'active': isStepFORM1}"
        >
          {{ $t('Dados pessoais') }}
        </div>
        <div
            class="nav-item"
            :class="{'hidden': isStepINITIAL || !hasFORM2, 'active': isStepFORM2}"
        >
          {{ $t('Acompanhante') }}
        </div>
        <div
            class="nav-item"
            :class="{'hidden': isStepINITIAL || isStepPAGAMENTO, 'active': isStepCONFIRMACAO || isStepDECLINE}"
        >
          {{ $t('Confirmação') }}
        </div>
        <div
            class="nav-item"
            :class="{'hidden': isStepINITIAL || !hasPAGAMENTO, 'active': isStepPAGAMENTO}"
        >
          {{ $t('Pagamento') }}
        </div>
      </div>
      <div class="content">
        <FIdentificacao ref="formIdentificacao" v-show="isStepINITIAL" @submit="() => iniciarClickHandle()"/>
        <div class="forms">
          <div class="confirmacao-header" v-show="isStepCONFIRMACAO">
            <div class="icon">
              <img src="../assets/icon-warning.png" alt="atenção">
            </div>
            <div class="text">
              <div class="l1">{{ $t('concl-1') }}</div>
              <div class="l2">
                <span class="font-bold">{{ $t('ATENÇÃO') }}:</span>
                {{ $t('concl-2') }}
              </div>
            </div>
          </div>
          <div class="form1" v-show="isStepFORM1 || isStepCONFIRMACAO || isStepDECLINE">
            <div class="disclaim-f2 mb-disc">
              <IConfirm
                :is-disabled="!isStepFORM1"
                v-model="confirmCruise" ref="confirm_cruise" class="col-lg"
                :label="$t('confirmCruise')"/>
            </div>
            <span v-show="confirmCruise">
              <FormF1 ref="formF1" v-if="isFormType1" @submit="() => proximoClickHandle()"/>
              <FormF2E1 ref="formF2E1" v-if="isFormType2" @submit="() => proximoClickHandle()"/>
              <FormF3 ref="formF3" v-if="isFormType3" @submit="() => proximoClickHandle()"/>
              <FormF4E1 ref="formF4E1" v-if="isFormType4" @submit="() => proximoClickHandle()"/>
              <FormF5E1 ref="formF5E1" v-if="isFormType5" @submit="() => proximoClickHandle()"/>
            </span>
            <FormF6 ref="formF6" v-if="isFormType6" @submit="() => proximoClickHandle()"/>
            <FormF7E1 ref="formF7E1" v-if="isFormType7" @submit="() => proximoClickHandle()"/>
            <FormF8 ref="formF8" v-if="isFormType8" @submit="() => proximoClickHandle()"/>
            <FormF9
                ref="formF9"
                v-if="isFormType9"
                @submit="() => proximoClickHandle()"
                @change-online-presencial="(v) => handleChangeOnlinePresencial(v)"
            />
            <FormF10
                ref="formF10"
                v-if="isFormType10"
                @submit="() => proximoClickHandle()"
                @change-online-presencial="(v) => handleChangeOnlinePresencial(v)"
            />
            <FormF11
                ref="formF11"
                v-if="isFormType11"
                @submit="() => proximoClickHandle()"
                @change-online-presencial="(v) => handleChangeOnlinePresencial(v)"
            />
            <FormF12 ref="formF12" v-if="isFormType12" @submit="() => proximoClickHandle()"/>
            <FormF13 ref="formF13" v-if="isFormType13" @submit="() => proximoClickHandle()"/>
          </div>
          <div class="form2" v-show="isStepFORM2 || isStepCONFIRMACAO">
            <div v-show="isStepFORM2" class="disclaim-f2">
              <div class="l1">{{ $t('disc-form2-1') }}</div>
              <div class="l2">{{ $t('disc-form2-2') }}</div>
            </div>

            <div v-if="hasFORM2">
              <div class="confirm-3" v-show="isStepCONFIRMACAO">
                <div class="accordion" id="dados_acompanhante_collapse">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                          type="button"
                          class="accordion-button collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseItem"
                          aria-expanded="false"
                          aria-controls="collapseItem"
                          @click="() => toogleMostrarDadosAcompanhante()"
                      >
                        + {{ $t('disc-form2-1') }}
                      </button>
                    </h2>
                    <div id="collapseItem" class="accordion-collapse collapse"
                         data-bs-parent="#dados_acompanhante_collapse"></div>
                  </div>
                </div>
              </div>
              <div :hidden="!mostrarDadosAcompanhante && isStepCONFIRMACAO && !isStepDECLINE"
                   :class="{'confirm-dados-acompanhante': mostrarDadosAcompanhante && isStepCONFIRMACAO}">
                <FormF2E2 ref="formF2E2" v-if="isFormType2" @submit="() => proximoClickHandle()"/>
                <FormF4E2 ref="formF4E2" v-if="isFormType4" @submit="() => proximoClickHandle()"/>
                <FormF5E2 ref="formF5E2" v-if="isFormType5" @submit="() => proximoClickHandle()"/>
                <FormF7E2 ref="formF7E2" v-if="isFormType7" @submit="() => proximoClickHandle()"/>
              </div>
            </div>

          </div>
          <div class="formPagamento" v-if="isStepPAGAMENTO">
            <!-- <div v-show="isStepPAGAMENTO" class="disclaim-f2">
              <div class="l1">{{ $t('disc-payment-1') }}</div>
              <div class="l2">{{ $t('disc-form2-2') }}</div>
            </div> -->
            <FormPagamento ref="formPagamento" @submit="() => pagamentoSubmitClickHandle()"/>
          </div>
          <div class="confirmacao-footer" v-show="isStepCONFIRMACAO">
            <i-checkbox v-model="authCheck.isTermos">
              {{ $t('Eu_li', {"artigo": " os "}) }}
              <a
                  :href="urlTermosInscricao"
                  target="_blank"
              >{{ $t('termos de inscrição') }}</a>.
            </i-checkbox>
            <i-checkbox v-model="authCheck.isImagem">
              {{ $t('Eu_li', {"artigo": " a "}) }}
              <a
                  :href="urlAuthUso"
                  target="_blank"
              >{{ $t('autorização de uso de imagem e voz') }}</a>.
            </i-checkbox>
          </div>
        </div>
      </div>
      <div class="footer">
        <button
            class="btn t2"
            v-show="!isStepINITIAL && !isStepFORM1 && !isStepPAGAMENTO"
            @click="() => voltarClickHandle()"
        >{{ $t('VOLTAR') }}
        </button>
        <VueButtonSpinner
            class="btn btn-spin t1"
            v-show="isStepINITIAL"
            @click.native="() => iniciarClickHandle()"
            :disabled="isOnIniciar"
            :is-loading="isOnIniciar"
        >INICIAR
        </VueButtonSpinner>
        <button
            class="btn t1"
            v-show="!isStepINITIAL && !isStepCONFIRMACAO && !isStepPAGAMENTO && !isStepDECLINE"
            @click="() => proximoClickHandle()"
            :disabled="isOnIniciar"
        >{{ $t('PRÓXIMO') }} &gt;
        </button>
        <VueButtonSpinner
            class="btn btn-spin t3 font-bold"
            v-show="isStepCONFIRMACAO"
            @click.native="() => concluirClickHandle()"
            :disabled="isOnConcluir"
            :is-loading="isOnConcluir"
        >{{ $t('CONCLUIR INSCRIÇÃO') }}
        </VueButtonSpinner>
        <VueButtonSpinner
            class="btn btn-spin t4 font-bold"
            v-show="isStepDECLINE"
            @click.native="() => declineClickHandle()"
            :disabled="isOnConcluir"
            :is-loading="isOnConcluir"
        >{{ $t('DECLINAR INSCRIÇÃO') }}
        </VueButtonSpinner>
      </div>
    </div>
    <div v-show="isStepDECLINADO">
      <div class="conclusao-show">
        <div class="l1">
          {{ name }}, {{ $t('decline-1') }}
        </div>
        <div class="l2">
          {{ $t('decline-2') }}
        </div>
        <img class="icon" src="../assets/decline.png" alt="Declinado">
        <div class="l3">
          {{ $t('decline-3') }} <br />
        </div>
        <div class="l3">
          <span class="font-bold">{{ $t('decline-4') }}</span>
        </div>
      </div>
    </div>
    <div v-show="isStepCONCLUSAO">
      <div class="conclusao-show" v-if="isCheckouConclusao">
        <div class="l1">
        </div>
        Obrigado {{ name }}. Estamos processando o seu pagamento.
        <div class="l2">
          Iremos notificar via e-mail sobre o andamento dessa transação.
        </div>
        <img class="icon" src="../assets/icon-confirm.svg" alt="Confirmado">
        <div class="l3">
          <span class="font-bold">Importante:</span> Fique de olho em seu e-mail, pois ele será o nosso principal canal de comunicação.
        </div>
      </div>
      <div class="conclusao-show" v-else>
        <div class="l1">
          {{ name }}, {{ $t('success-1') }}
        </div>
        <div class="l2">
          {{ $t('success-2') }}
        </div>
        <img class="icon" src="../assets/icon-confirm.svg" alt="Confirmado">
        <div class="l3">
          <span class="font-bold">{{ $t('Importante') }}:</span>
          {{ $t('success-3') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueButtonSpinner from 'vue-button-spinner';
import {identification} from "../services/api/identification";
import {register} from "../services/api/register";
import {decline} from "../services/api/decline";
import {TYPES_FORM} from "../services/api/types-form";
import IConfirm from "./inputs/i-confirm";
import {notification} from "../services/notification";
import FIdentificacao from "./forms/f-identificacao";
import FormPagamento from "./forms/form-pagamento";
import FormF1 from "./forms/form-f1";
import FormF2E1 from "./forms/form-f2-e1";
import FormF2E2 from "./forms/form-f2-e2";
import FormF3 from "./forms/form-f3";
import FormF5E1 from "./forms/form-f5-e1";
import FormF5E2 from "./forms/form-f5-e2";
import FormF6 from "./forms/form-f6";
import FormF7E1 from "./forms/form-f7-e1";
import FormF7E2 from "./forms/form-f7-e2";
import FormF8 from "./forms/form-f8";
import FormF9 from "./forms/form-f9";
import FormF10 from "./forms/form-f10";
import FormF11 from "./forms/form-f11";
import FormF12 from "./forms/form-f12";
import FormF13 from "./forms/form-f13";
import FormF4E1 from "./forms/form-f4-e1";
import FormF4E2 from "./forms/form-f4-e2";
import ICheckbox from "./inputs/i-checkbox";

const STEP_STATE = {
  INITIAL: 'INITIAL',
  FORM1: 'FORM1',
  FORM2: 'FORM2',
  CONFIRMACAO: 'CONFIRMACAO',
  PAGAMENTO: 'PAGAMENTO',
  CONCLUSAO: 'CONCLUSAO',
  DECLINADO: 'DECLINADO',
  DECLINAR: 'DECLINAR',
};

export default {
  name: "i-form",
  components: {
    IConfirm,
    ICheckbox,
    FormPagamento,
    FormF13,
    FormF12,
    FormF11,
    FormF10,
    FormF9,
    FormF8,
    FormF7E2,
    FormF7E1,
    FormF6,
    FormF5E2,
    FormF5E1,
    FormF4E2,
    FormF4E1,
    FormF2E2,
    FormF2E1,
    FormF3,
    FormF1,
    FIdentificacao,
    VueButtonSpinner,
  },
  props: {
    hasIdentificacao: Boolean,
  },
  data: () => ({
    isStarted: false,
    currentStep: null,
    typeForm: null,
    contactId: null,
    isOnIniciar: false,
    confirmCruise: null,
    isOnConcluir: false,
    mostrarDadosAcompanhante: true,
    name: '',
    last_name: '',
    email: '',
    identificationResp: null,
    isCheckouConclusao: false,
    withoutPagamentoByRule: false,
    authCheck: {
      isTermos: false,
      isImagem: false,
    },
  }),
  methods: {
    setStepINITIAL() {
      this.currentStep = STEP_STATE.INITIAL;
    },
    setStepFORM1() {
      this.currentStep = STEP_STATE.FORM1;
      this.setFormsDisabled(false);
    },
    setStepFORM2() {
      this.scrollTop();
      this.currentStep = STEP_STATE.FORM2;
      this.setFormsDisabled(false);
    },
    setStepCONFIRMACAO() {
      this.scrollTop();
      this.currentStep = STEP_STATE.CONFIRMACAO;
      this.setFormsDisabled(true);
      notification.error('Confira suas informações, não será possível altera-las posteriormente', 'ATENÇÃO');
    },
    setStepDECLINAR() {
      this.scrollTop();
      this.currentStep = STEP_STATE.DECLINAR;
      this.setFormsDisabled(true);
      notification.error('Você está declinando a participação ao evento MAGNEXT 2025', 'ATENÇÃO');
    },
    setStepPAGAMENTO() {
      const formValue = this.getPagamentoData();
      this.scrollTop();
      this.currentStep = STEP_STATE.PAGAMENTO;
      this.$nextTick(() => {
        this.$refs.formPagamento.initValue({
          id: this.contactId,
          name: formValue.name,
          phone: formValue.phone,
          cpf: formValue.cpf,
          email: formValue.email,
          isOnline: formValue.isOnline,
          showMAG: formValue.showMAG,
        })
      });
    },
    setStepCONCLUSAO() {
      this.currentStep = STEP_STATE.CONCLUSAO;
    },
    setStepDECLINADO() {
      this.currentStep = STEP_STATE.DECLINADO;
    },
    getPagamentoData() {
      let name = this.identificationResp?.name;
      let phone = this.identificationResp?.phone;
      let email = this.identificationResp?.email;
      let isOnline = Boolean(this.identificationResp?.isOnline);
      let showMAG = this.isFormType9;
      if (!this.isStepINITIAL) {
        const [componentRef] = this.getAllFormRef()
        const formValue = componentRef.getValue();
        name = formValue.name;
        phone = formValue.phone;
        email = formValue.email;
        isOnline = formValue.isOnline;
      }
      return {
        name,
        phone,
        email,
        isOnline,
        showMAG,
      }

    },
    handleChangeOnlinePresencial(onlinePresencialValue) {
      this.withoutPagamentoByRule = !!onlinePresencialValue;
    },
    setFormsDisabled(isDisabled) {
      const forms = this.getAllFormRef();
      this.$nextTick(() => forms.forEach(form => form?.setDisabled(isDisabled)));
    },
    async voltarClickHandle() {
      if (this.isStepFORM2) {
        this.setStepFORM1();
      }
      if (this.isStepCONFIRMACAO || this.isStepDECLINE) {
        if (this.hasFORM2 && !this.isStepDECLINE) {
          this.setStepFORM2();
        } else {
          this.setStepFORM1();
        }
      }
    },
    async iniciarClickHandle() {
      if (this.isOnIniciar) {
        return;
      }
      if (!this.$refs.formIdentificacao.isValid()) {
        notification.error(identResp?.message ?? 'Verique se o e-mail informado esta correto');
        return;
      }
      this.isOnIniciar = true;
      const identResp = await identification(this.$refs.formIdentificacao.getData());
      this.isOnIniciar = false;
      if (!identResp.ok) {
        notification.error(identResp?.message ?? 'Verique se o e-mail informado esta correto');
        return;
      }
      this.typeForm = identResp.typeForm;
      this.contactId = identResp.contactId;
      this.name = identResp.name;
      this.last_name = identResp.last_name;
      this.email = identResp.email;
      this.identificationResp = identResp.resp;
      if (identResp.resp?.need_payment && !identResp.resp?.payment_completed) {
        this.setStepPAGAMENTO();
      } else {
        this.setStepFORM1();
      }
      this.$nextTick(() => {
        this.updateNameEmail();
        if (this.isFormType5) {
          this.$i18n.locale = 'en';
        }
      });
    },
    updateNameEmail() {
      this.getCurrentFormRef().setNameEmail(this.name, this.last_name, this.email);
    },
    updateDataPayment(resp) {
      this.$refs['formPagamento'].setNameEmail(resp);
    },
    async proximoClickHandle() {
      if (!this.getCurrentFormRef().isValid() && this.confirmCruise) {
        notification.error(this.$t('message-required'));
        return;
      }
      if (!this.confirmCruise) {
        this.mostrarDadosAcompanhante = false;
        this.setStepDECLINAR();
        // await this.declineClickHandle();
      } else {
        if (this.isStepFORM2) {
          this.setStepCONFIRMACAO();
        }
        if (this.isStepFORM1) {
          if (this.hasFORM2) {
            this.setStepFORM2();
          } else {
            this.setStepCONFIRMACAO();
          }
        }
      }
      // if (this.isStepFORM2) {
      //   this.setStepCONFIRMACAO();
      // }
      // if (this.isStepFORM1) {
      //   if (this.hasFORM2) {
      //     this.setStepFORM2();
      //   } else {
      //     this.setStepCONFIRMACAO();
      //   }
      // }
    },
    pagamentoSubmitClickHandle() {
      this.isCheckouConclusao = true;
      this.setStepCONCLUSAO();
    },
    async declineClickHandle() {
      if (this.isOnConcluir) {
        return;
      }

      // if (!this.authCheck.isImagem || !this.authCheck.isTermos) {
      //   notification.error(this.$t('err-concordo'));
      //   return;
      // }

      this.isOnConcluir = true;

      let name = '';
      let resp;
      try {
        const declineForm = { confirmCruise: this.confirmCruise };
        resp = await decline(this.contactId, declineForm);
        name = resp.name;
        this.isOnConcluir = false;
      } catch (err) {
        console.log(err);
        notification.error(err?.errMsg ?? 'Houve um problema para concluir inscrição');
        this.isOnConcluir = false;
        return;
      }
      // if (this.name) {
      //   name = this.name;
      // }

      this.name = name.split(' ')[0];
      if (this.needStepPayment()) {
        this.contactId = resp.id;
        this.setStepPAGAMENTO();
      } else {
        this.setStepDECLINADO();
      }
    },
    async concluirClickHandle() {
      if (this.isOnConcluir) {
        return;
      }

      if (!this.authCheck.isImagem || !this.authCheck.isTermos) {
        notification.error(this.$t('err-concordo'));
        return;
      }

      this.isOnConcluir = true;

      let name = '';
      let resp;
      try {
        const [refForm1, refForm2] = this.getAllFormRef();
        resp = await register(this.typeForm, this.contactId, refForm1.getValue(), refForm2?.getValue());
        name = resp.name;
        this.isOnConcluir = false;
      } catch (err) {
        console.log(err);
        notification.error(err?.errMsg ?? 'Houve um problema para concluir inscrição');
        this.isOnConcluir = false;
        return;
      }
      // if (this.name) {
      //   name = this.name;
      // }

      this.name = name.split(' ')[0];
      if (this.needStepPayment()) {
        this.contactId = resp.id;
        this.setStepPAGAMENTO();
      } else {
        this.setStepCONCLUSAO();
      }
    },
    needStepPayment() {
      if (!this.hasPAGAMENTO) {
        return false;
      }
      const [refForm1] = this.getAllFormRef();
      return !refForm1.getValue().isOnline;
    },
    getCurrentFormRef() {
      switch (this.typeForm) {
        case TYPES_FORM.FORM_1:
          return this.$refs.formF1;
        case TYPES_FORM.FORM_2:
          return this.isStepFORM1 ? this.$refs.formF2E1 : this.$refs.formF2E2;
        case TYPES_FORM.FORM_3:
          return this.$refs.formF3;
        case TYPES_FORM.FORM_4:
          return this.isStepFORM1 ? this.$refs.formF4E1 : this.$refs.formF4E2;
        case TYPES_FORM.FORM_5:
          return this.isStepFORM1 ? this.$refs.formF5E1 : this.$refs.formF5E2;
        case TYPES_FORM.FORM_6:
          return this.$refs.formF6;
        case TYPES_FORM.FORM_7:
          return this.isStepFORM1 ? this.$refs.formF7E1 : this.$refs.formF7E2;
        case TYPES_FORM.FORM_8:
          return this.$refs.formF8;
        case TYPES_FORM.FORM_9:
          return this.$refs.formF9;
        case TYPES_FORM.FORM_10:
          return this.$refs.formF10;
        case TYPES_FORM.FORM_11:
          return this.$refs.formF11;
        case TYPES_FORM.FORM_12:
          return this.$refs.formF12;
        case TYPES_FORM.FORM_13:
          return this.$refs.formF13;
      }
    },
    getAllFormRef() {
      switch (this.typeForm) {
        case TYPES_FORM.FORM_1:
          return [this.$refs.formF1];
        case TYPES_FORM.FORM_2:
          return [this.$refs.formF2E1, this.$refs.formF2E2];
        case TYPES_FORM.FORM_3:
          return [this.$refs.formF3];
        case TYPES_FORM.FORM_4:
          return [this.$refs.formF4E1, this.$refs.formF4E2];
        case TYPES_FORM.FORM_5:
          return [this.$refs.formF5E1, this.$refs.formF5E2];
        case TYPES_FORM.FORM_6:
          return [this.$refs.formF6];
        case TYPES_FORM.FORM_7:
          return [this.$refs.formF7E1, this.$refs.formF7E2];
        case TYPES_FORM.FORM_8:
          return [this.$refs.formF8];
        case TYPES_FORM.FORM_9:
          return [this.$refs.formF9];
        case TYPES_FORM.FORM_10:
          return [this.$refs.formF10];
        case TYPES_FORM.FORM_11:
          return [this.$refs.formF11];
        case TYPES_FORM.FORM_12:
          return [this.$refs.formF12];
        case TYPES_FORM.FORM_13:
          return [this.$refs.formF13];
      }
    },
    toogleMostrarDadosAcompanhante() {
      this.mostrarDadosAcompanhante = !this.mostrarDadosAcompanhante;
    },
    scrollTop() {
      window.scrollTo({top: 150, behavior: 'smooth'});
    },
  },
  computed: {
    isStepINITIAL() {
      return this.currentStep === STEP_STATE.INITIAL;
    },
    isStepFORM1() {
      return this.currentStep === STEP_STATE.FORM1;
    },
    isStepFORM2() {
      return this.currentStep === STEP_STATE.FORM2;
    },
    isStepCONFIRMACAO() {
      return this.currentStep === STEP_STATE.CONFIRMACAO;
    },
    isStepPAGAMENTO() {
      return this.currentStep === STEP_STATE.PAGAMENTO;
    },
    isStepCONCLUSAO() {
      return this.currentStep === STEP_STATE.CONCLUSAO;
    },
    isStepDECLINADO() {
      return this.currentStep === STEP_STATE.DECLINADO;
    },
    isStepDECLINE() {
      return this.currentStep === STEP_STATE.DECLINAR;
    },
    hasFORM2() {
      if (this.typeForm) {
        return !(this.isFormType1 || this.isFormType3 || this.isFormType6 || this.isFormType8 || this.isFormType9 || this.isFormType10 || this.isFormType11 || this.isFormType12 || this.isFormType13);
      }
      return false;
    },
    hasPAGAMENTO() {
      if (this.withoutPagamentoByRule) {
        return false;
      }
      if (this.typeForm) {
        return this.isFormType9 || this.isFormType10 || this.isFormType11
      }
      return false;
    },
    isFormType1() {
      return this.typeForm === TYPES_FORM.FORM_1;
    },
    isFormType2() {
      return this.typeForm === TYPES_FORM.FORM_2;
    },
    isFormType3() {
      return this.typeForm === TYPES_FORM.FORM_3;
    },
    isFormType4() {
      return this.typeForm === TYPES_FORM.FORM_4;
    },
    isFormType5() {
      return this.typeForm === TYPES_FORM.FORM_5;
    },
    isFormType6() {
      return this.typeForm === TYPES_FORM.FORM_6;
    },
    isFormType7() {
      return this.typeForm === TYPES_FORM.FORM_7;
    },
    isFormType8() {
      return this.typeForm === TYPES_FORM.FORM_8;
    },
    isFormType9() {
      return this.typeForm === TYPES_FORM.FORM_9;
    },
    isFormType10() {
      return this.typeForm === TYPES_FORM.FORM_10;
    },
    isFormType11() {
      return this.typeForm === TYPES_FORM.FORM_11;
    },
    isFormType12() {
      return this.typeForm === TYPES_FORM.FORM_12;
    },
    isFormType13() {
      return this.typeForm === TYPES_FORM.FORM_13;
    },
    urlTermosInscricao() {
      if (this.isFormType5) {
        return "https://xcode-eventfy.sfo3.digitaloceanspaces.com/magseguros/settings/termos-de-inscricao.pdf";
      }
      return "https://xcode-eventfy.sfo3.digitaloceanspaces.com/magseguros/settings/termos-de-inscricao.pdf";
    },
    urlAuthUso() {
      if (this.isFormType5) {
        return "https://xcode-eventfy.sfo3.digitaloceanspaces.com/magseguros/settings/autorizacao-de-imagem.pdf";
      }
      return "https://xcode-eventfy.sfo3.digitaloceanspaces.com/magseguros/settings/autorizacao-de-imagem.pdf";
    },
  },
  created() {
    if (this.hasIdentificacao) {
      this.currentStep = STEP_STATE.INITIAL;
    } else {
      this.currentStep = STEP_STATE.FORM1;
      this.typeForm = TYPES_FORM.FORM_9;
      this.contactId = null;
      this.name = null;
      this.last_name = null;
      this.email = null;
    }

    // this.currentStep = STEP_STATE.PAGAMENTO;
  }
}
</script>

<style scoped lang="scss">
@import "../styles/color";


.container {
  overflow: hidden;
  --padding-h: 79px;
  @media (max-width: 992px) {
    --padding-h: 29px;
  }
  border-radius: 5px;
  background: #FFFFFF;
  border: 1px solid $gray1;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.06);

  > {
    .content, .footer {
      padding-inline: var(--padding-h);
    }
  }
}

.btn.t1::v-deep .spinner {
  border-top-color: #f6f6f6;
}

.btn.t3::v-deep .spinner {
  border-top-color: #f6f6f6;
}

.nav {
  height: 50px;
  display: flex;
  --color: #00000070;
  padding-top: 10px;
  counter-reset: section;

  .nav-item {
    --color: #00000070;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &::before {
      counter-increment: section;
      content: counter(section) '. ';
    }

    &.active {
      --color: #000000;
      overflow: visible;
    }

    &.hidden {
      display: none;
    }

    padding-inline: 0.6ch;
    font-size: 20px;
    color: var(--color);
    text-align: center;
    flex: 1;
    border-bottom: 1px solid var(--color);
    font-weight: 400;
  }
}

.legenda-l1 {
  font-size: 0.75em;
  opacity: 0.8;
}

.content {
  margin-top: 63px;
}

.confirmacao-header {
  display: flex;
  margin-bottom: 25px;

  .icon {
    $size: 72px;
    width: $size;
    height: $size;

    img {
      width: 42px;
      height: 42px;
      object-fit: cover;
    }

    margin-right: 12px;
  }


  .l1 {
    color: $black4;
    font-size: 18px;
    font-weight: 500;
  }

  .l2 {
    color: $red4;
    font-size: 14px;
    font-weight: 500;
  }
}

.disclaim-f2 {
  color: $black4;
  margin-bottom: 15px;

  &.mb-disc {
    margin-bottom: 35px;
  }

  .l1 {
    font-size: 16px;
    font-weight: 500;
  }

  .l2 {
    font-size: 14px;
    font-weight: 500;
  }
}

.confirm-dados-acompanhante {
  border: 1px solid $gray2;
  padding: 12px 18px;
}

.footer {
  margin-top: 26px;
  margin-bottom: 33px;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.conclusao-show {
  border-radius: 5px;
  background: #FFFFFF;
  border: 1px solid $gray1;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 42px;
  padding-bottom: 59px;


  color: $black4;

  .l1 {
    font-weight: 500;
    font-size: 24px;
  }

  .l2 {
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 35px;
  }

  .icon {
    $size: 150px;
    width: $size;
    height: $size;
    object-fit: cover;
    margin-bottom: 28px;
  }

  .l3 {
    font-size: 16px;
    font-weight: 400;
  }
}

</style>
